import React, { useState, useEffect } from 'react';
import { Table, Button,Segment,Search, Modal } from 'semantic-ui-react';
import {allBranchPrice,syncBranchPrice,resyncAllPricesAPI,downloadAllPricesAPI} from '../../../constants';
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import AppPagination from '../../../components/cmp-pagination/Pagination';
import './PriceManagement.css';
import { useToasts } from "react-toast-notifications";

function PriceManagement(){


    /* VARIABLE DECLARATIONS
	=================================================================================*/
    const[ prices,setPrices ]=useState([]);
    const [var_mdl_progress, set_mdl_progress] = useState(false);
    const [ page, setPage ] = useState(1);
    const [ limit, setLimit ] = useState(10);
    const [totalBranch, setTotalBranch] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const { addToast } = useToasts();
    const chipColors = ["#FFCDD2", "#C8E6C9", "#BBDEFB", "#FFECB3", "#D1C4E9", "#FFF59D"];

    /* EVENT LISTENERS
	=================================================================================*/
  
      useEffect(() => {
        if (searchQuery.length > 1) {
          const delayDebounceFn = setTimeout(async () => {
            getListOfPrices(0, limit, searchQuery);
          }, 1200);
    
          return () => clearTimeout(delayDebounceFn);
        } else if (searchQuery === '') {
          getListOfPrices();
        }
      }, [searchQuery, page, limit]);


    /* Async functions
	---------------------------------------------------------------------------------*/
  async function getListOfPrices(page_no, limit_range, q) {
        set_mdl_progress(true);
        var query_params = "";
        if (q) {
          query_params = `?q=${q}&page=${page_no ? page_no : page}&limit=${
            limit_range ? limit_range : limit
          }`;
        } else {
          query_params = `?page=${page_no ? page_no : page}&limit=${
            limit_range ? limit_range : limit
          }`;
        }
          
        await fetch(allBranchPrice + query_params)
        .then(response => response.json())
        .then(res => {
            var priceFetch=[];
            const{data}=res
            if (res.code === 200) {
                priceFetch = data;
              } else {
                priceFetch = [];
              }
              if ((page && page > 1) || (page_no && page_no > 1)){
                priceFetch = [...prices, ...priceFetch];
             
            }
            setPrices(priceFetch);
            setTotalBranch(res.total_branch);
        })
        .catch(error=>{console.error("Error:",error)});
        set_mdl_progress(false);
    }

    async function asyncAPICall(branchID) {
      set_mdl_progress(true);
        await fetch(syncBranchPrice+branchID)
        .then(response => response.json())
        .then(res => {
            if (res.code === 200) {
              addToast(res?.body?.success, {
                appearance: "success",
                autoDismiss: true,
              });
              getListOfPrices();
            } else {
              addToast(
                res?.body?.error ||
                  res?.body?.message ||
                  res.error?.message ||
                  "There is some server side issue. Please try again later.",
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
            }
        })
        .catch(error=>{console.error("Error:",error)});
        set_mdl_progress(false);
    }

     /**
     * API call for resync all prices
     */
    async function asyncAllPrices() {
      set_mdl_progress(true);
      await fetch(resyncAllPricesAPI)
      .then(response => response.json())
      .then(res => {
          if (res.code === 200) {
            addToast(res?.body?.success, {
              appearance: "success",
              autoDismiss: true,
            });
            getListOfPrices();
          } else {
            addToast(
              res?.body?.error ||
                res?.body?.message ||
                res.error?.message ||
                "There is some server side issue. Please try again later.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          }
      })
      .catch(error=>{console.error("Error:",error)});
      set_mdl_progress(false);
    }


     async function downloadReport() {
        set_mdl_progress(true);
        const url = `${downloadAllPricesAPI}`;
        try {
          window.open(url, "_blank");
        } catch (error) {
          console.error("Error:", error);
        }
        set_mdl_progress(false);
      }


    /* EVENT FUNCTIONS
	=================================================================================*/
  function renderTableItems(val, index) {
    const itemRows = [
      <Table.Row key={index}>
        <Table.Cell>{val.branch_id}</Table.Cell>
        <Table.Cell>{val.branch_name}</Table.Cell>
        <Table.Cell>
          <div className='app-content-action'>
          <Button 
            disabled={false}     
            color="green"
            onClick={() => asyncAPICall(val.branch_id)}
          >
            <img src="/resync.svg" alt="sync" />
            Resync
          </Button>
           </div>
        </Table.Cell>
      </Table.Row>,
    ];
  
    let parsedArray = [];
    try {
      const serviceCodeString = val.service_code.trim();
  
      // Check if the JSON starts and ends correctly
      if (serviceCodeString.startsWith("[") && !serviceCodeString.endsWith("]")) {
        // Attempt to fix truncated JSON by closing it
        const correctedString = serviceCodeString + "]";
        parsedArray = JSON.parse(correctedString);
      } else {
        parsedArray = JSON.parse(serviceCodeString);
      }
    } catch (error) {
      console.error("Error parsing service_code:", error.message);
      console.log("Service Code causing issue:", val.service_code);
  
      // Display a fallback message
      itemRows.push(
        <Table.Row key={"row-error-" + index}>
          <Table.Cell colSpan="6" style={{ color: "red" }}>
            Unable to display service details due to invalid data.
          </Table.Cell>
        </Table.Row>
      );
      return itemRows;
    }
  
    // Add rows for parsed service details
    itemRows.push(
      <Table.Row key={"row-expand-" + index}>
        <Table.Cell colSpan="6">
          <Segment padded>
            <div className="chip-header">
              <strong>Service Details</strong>
            </div>
            <div className="chip-container">
              {parsedArray.map((data, ind) => (
                <div
                  className="chip"
                  key={`chip-${index}-${ind}`}
                  style={{
                    backgroundColor: chipColors[ind % chipColors.length], // Assign color based on index
                  }}
                >
                  <div className="chip-content">
                    <span className="chip-label">Service Code:</span>
                    <span className="chip-value">{data.serviceCode}</span>
                  </div>
                  <div className="chip-content">
                    <span className="chip-label">Price:</span>
                    <span className="chip-value">{data.price || "N/A"}</span>
                  </div>
                </div>
              ))}
            </div>
          </Segment>
        </Table.Cell>
      </Table.Row>
    );
  
    return itemRows;
  }
  
  
    
      /**
       * We will get the text from the search and send it in our APi call
       * @param {*} event on search text change event
       */
      function doSearch(event) {
        const value = event.target.value.toString().toLowerCase();
        setPage(1);
        setSearchQuery(value);
      }


    /* RENDER APP
	=================================================================================*/
    return(
        <>
        <div className="app-content">
          <div className="app-building-container">
            <div className="app-content-action">
          
            </div>
  
            <div className="app-card">
              {/* Card title and search div */}
              <div className="app-card-header building-managment-header-card">
              <div className="app-card-title">
                Price Management (
                  {prices.length}
                  )
                </div>
                
                <div className="app-content-search" style={{marginRight:"8px"}}>
                  <Search
                    placeholder="Search here (branch id, branch name, service code)"
                    onSearchChange={(value) => doSearch(value)}
                    showNoResults={false}
                    value={searchQuery}
                  />
                </div>
                <div className="app-content-action" style={{marginBottom:"0px"}}>
                  <div className="app-content-action-right">
                    <Button
                        color="green"
                        onClick={() => asyncAllPrices()}
                      >
                        <img src="/resync.svg" alt="sync" />
                        Resync
                      </Button>
        
                      <Button
                        color="blue"
                        onClick={() => {
                          downloadReport();
                        }}
                        disabled={!prices.length}
                        content="Download"
                      >
                        <img src="/history.png" alt="icon" />
                        Export
                      </Button>
                </div>
                </div>
              </div>
  
              {/* Main table section */}
              <div className="app-table-wrapper">
                <Table unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Branch ID</Table.HeaderCell>
                      <Table.HeaderCell>Branch</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {!var_mdl_progress && prices.map((val, index) =>
                      renderTableItems(val, index)
                    )}
                  </Table.Body>
                </Table>
                {!var_mdl_progress && prices.length === 0 && (
                  <div className="nodata-placeholder">
                    There is no prices to display
                  </div>
                )}
              </div>
            </div>
              <AppPagination
              limit={limit}
              setLimit={setLimit}
              page={page}
              setPage={setPage}
              rowsTotal={prices.length}
              totalCount={totalBranch}
              showAll={false}
            />
          </div>
        </div>
        {/***** MODAL: PROGRESS **************************************************************************/}
        <Modal
          id="mdl-progress"
          dimmer={"inverted"}
          open={var_mdl_progress}
          closeOnDimmerClick={false}
          closeOnEscape={false}
          onClose={() => {}}
        >
          <MDL_PROGRESS
            set_mdl_open={set_mdl_progress}
            var_modaltitle="Please wait"
            var_message={"Stay on this page until this process finish."}
          ></MDL_PROGRESS>
        </Modal>
  
        {/***** END MODAL: PROGRESS **************************************************************************/}
      </>
    )

}

export default PriceManagement;